.App {
  text-align: center;
}

.app-logo {
  pointer-events: none;
  min-width: 80vw;
  max-width: 80vw;
  height: auto;
}

.App-header {
  background-color: rgba(40, 44, 52, 0.062);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-title {
	text-transform: uppercase;
	font-weight: 700;
}

.color-main {
	color: #121847;
}

.color-second {
	color: #666666;
}